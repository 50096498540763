.pagenotfound-container {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e1eaed url(/assets/images/custom/404/mobile-404-image.jpg) 50% 100% no-repeat;
  background-size: 100%;
  height: 95vh;
  top: 0px;
  bottom: 0px;

  .empty-msg {
    width: 100%;
    text-align: center;
    font-size: 22px;
    color: #222;
    box-sizing: border-box;
    padding: 10px 0 20px 0;
    font-weight: 300;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-size: 40px;
      color: #638d89;
      font-weight: normal;
      margin: 0;
      margin-top: 6px;
      span {
        display: block;
        font-size: 20px;
        font-weight: normal;
        margin-top: 0;
        color: #638d89;
      }
    }
    .info {
      line-height: 22px;
      font-size: 14px;
      max-width: 420px;
      margin: 0 auto;
      color: #000;
      margin: 10px 0 20px 0;
    }
    .all-buttons {
      width: 100%;
      :global {
        i.material-icons {
          margin-right: 5px;
        }
        .popular-link {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          li {
            a {
              color: #fff;
              display: flex;
              align-items: center;
              margin-right: 5px;
              border-radius: 5px;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
              font-size: 14px;
              font-weight: 400;
              padding: 10px;
              margin: 0 10px 10px 0;
            }
          }
        }
      }
    }
  }
}

.pagenotfound-desktopContainer {
  background-color: #dde6e5;
  top: 150px;
  width: 100%;
  overflow: auto;
  height: -webkit-calc(100% - 198px);
  height: -moz-calc(100% - 198px);
  height: calc(100% - 198px);
  .desktop-contentArea {
    padding: 0 20px;
    width: -webkit-calc(100% - 40px);
    width: -moz-calc(100% - 40px);
    width: calc(100% - 40px);
    height: 100%;
    .desktop-contentAreaIn {
      background: url(/assets/images/custom/404/desktop-404-image.jpg) 100% 0 no-repeat;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: 100% 0;
      height: 100%;
      max-width: 1170px;
      margin: 0 auto;
      .desktop-left-sec {
        width: 35%;
        padding-top: 20px;
        .empty-msg {
          margin: 0;
          padding: 0;
          p {
            margin-bottom: 15px;
            text-align: justify;
          }
          .title {
            margin-top: 0;
            margin-bottom: 15px;
            font-size: 70px;
            color: #638d89;
            font-weight: bold;
            line-height: 1.2;
            span {
              display: block;
              font-size: 32px;
              font-weight: normal;
              margin-top: 0;
              color: #638d89;
            }
          }
          .info {
            margin-bottom: 15px;
            letter-spacing: 0;
            font-size: 14px;
            line-height: 1.2;
          }
        }
        .desktop-popular-link {
          :global {
            ul {
              overflow: hidden;
              li {
                line-height: 1.6;
                margin-top: 5px;
                a {
                  display: inline-block;
                  color: #2179d0;
                  font-size: 16px;
                  .material-icons {
                    position: relative;
                    top: 5px;
                    margin-top: -5px;
                    margin-right: 10px;
                    color: #638d89;
                  }
                }
              }
            }
          }
        }
        :global {
          .download-app {
            margin-top: 10px;
            h3 {
              font-size: 18px;
              color: #222222;
              font-style: normal;
              font-weight: normal;
              line-height: 1.4;
              margin-bottom: 0.5rem;
              margin-top: 0.2rem;
              text-rendering: optimizeLegibility;
            }
            ul {
              list-style: none;
              margin: 0;
              li {
                margin-top: 5px;
                display: inline-block;
                margin-left: 10px;
                padding: 0;
              }
              li:first-child {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}
